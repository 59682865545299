import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { getStates } from 'actions/getStates';
import { Tasks } from 'api/generated/enums';
import { IAddressDto, IPersonalInfoDto, TaskDto } from 'api/generated/models';
import GENDERS from 'constants/genders';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import SummaryRowItem from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/SummaryRowItem';
import SummarySectionHeader from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/SummarySectionHeader';
import React, { useEffect, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import {
    formatDateForDisplay,
    formatGender,
    formatPhoneNumber,
    formatStateNameFromAbbrev,
    formatYesOrNo,
} from 'utilities/format';
import { hasValue } from 'utilities/index';

const PersonalInfoSection = ({ tasks }: { tasks: TaskDto[] }) => {
    const dispatch = useThunkDispatch();
    const { userId } = useUserProps();
    const { ssnTask, states } = useSelector((appState: AppStore) => ({
        ssnTask: appState.socialSecurityNumbers,
        states: appState.states,
    }));
    const task = tasks.find((t) => t.taskId === Tasks.PersonalInfo);
    const taskId = task?.globalId as string;
    const chunk = task?.response as IPersonalInfoDto;
    const primarySsn = ssnTask.socialSecurityNumbers?.find((ssn) => ssn.entityKeyId === userId);

    const addressTask = tasks.find((t) => t.taskId === Tasks.PrimaryAddressInfo);
    const address = addressTask?.response as IAddressDto;
    const stateAbbrev = address.state;

    const selectedStateName = useMemo(() => formatStateNameFromAbbrev(states, stateAbbrev), [
        stateAbbrev,
        states,
    ]);

    useEffect(() => {
        dispatch(getStates());
    }, [dispatch]);

    return (
        <Grid container direction="column" item rowGap={1}>
            <SummarySectionHeader label="Personal Info" taskId={taskId} />
            <Divider sx={{ mb: 1, mt: -1 }} />
            <SummaryRowItem content={chunk.firstName} label="Legal First Name" />
            <SummaryRowItem content={chunk.lastName} label="Last Name" />
            {hasValue(chunk.preferredName) && (
                <SummaryRowItem content={chunk.preferredName} label="Preferred First Name" />
            )}
            <SummaryRowItem content={formatGender(chunk.gender as GENDERS)} label="Gender" />
            <SummaryRowItem content={formatDateForDisplay(chunk.birthdate)} label="Date of Birth" />
            <SummaryRowItem content={formatYesOrNo(chunk.usesTobacco)} label="Tobacco Use" />
            <SummaryRowItem content={formatYesOrNo(chunk.usCitizen)} label="U.S. Citizen" />
            <SummaryRowItem
                content={formatYesOrNo(chunk.isLegalResident)}
                label={`Legal Resident of ${selectedStateName}`}
            />
            <SummaryRowItem content={formatYesOrNo(chunk.isIncarcerated)} label="Incarcerated" />
            <SummaryRowItem content={formatPhoneNumber(chunk.phone)} label="Phone Number" />
            <SummaryRowItem content={primarySsn?.socialSecurityNumber} label="SSN" useMask />
        </Grid>
    );
};

export default hot(module)(PersonalInfoSection);
