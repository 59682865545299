import Box from '@mui/material/Box';
import DateTextField from 'components/DateTextField';
import Select from 'components/Select';
import TextField from 'components/TextField';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import FormTitle from 'pages/survey/FormTitle';
import { saveMemberForm } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import React, { useCallback } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { yesOrNo } from 'reducers/options';
import { formatDateForDisplay } from 'utilities/format';
import { boolean, object, string } from 'yup';

const ONE_HUNDRED = 100;
const MAX_JOB_TITLE_LENGTH = 256;
const schema = object({
    dateOfBirth: string()
        .required()
        .isValidDate()
        .label('Date of Birth'),
    firstName: string()
        .trim()
        .required()
        .max(ONE_HUNDRED)
        .label('First Name'),
    gender: string()
        .required()
        .label('Gender'),
    hireDate: string()
        .required()
        .isValidDate()
        .label('Hire Date'),
    isIncarcerated: boolean()
        .typeError('Incarcerated is a required field')
        .required()
        .label('Incarcerated'),
    isLegalResident: boolean()
        .typeError('Legal Resident of Your State is a required field')
        .required()
        .label('Legal Resident of Your State'),
    jobTitle: string()
        .trim()
        .required()
        .max(MAX_JOB_TITLE_LENGTH)
        .label('Job Title'),
    lastName: string()
        .trim()
        .required()
        .max(ONE_HUNDRED)
        .label('Last Name'),
    usCitizen: boolean()
        .typeError('U.S. Citizen is a required field')
        .required()
        .label('U.S. Citizen'),
});

const MemberForm = () => {
    const dispatch = useThunkDispatch();
    const { data, genderList } = useSelector((state: AppStore) => ({
        data: { ...state.surveyState.member },
        genderList: state.options.genderList,
    }));
    if (data.dateOfBirth) {
        data.dateOfBirth = formatDateForDisplay(data.dateOfBirth) as string;
    }
    const {
        dateOfBirth,
        firstName,
        gender,
        hireDate,
        isIncarcerated,
        isLegalResident,
        jobTitle,
        lastName,
        preferredName,
        usCitizen,
    } = data;
    const { errors, validate } = useForm(schema);

    const onChange = useCallback(
        (e) => {
            const newMemberFormData = { [e.target.name]: e.target.value };
            dispatch(saveMemberForm(newMemberFormData));
        },
        [dispatch]
    );
    const handleSubmit = useCallback(async () => {
        const { isValid } = await validate(data);
        if (isValid) {
            dispatch(saveMemberForm(data));
        }
        return isValid;
    }, [validate, data, dispatch]);

    return (
        <SurveyFormWrapper onSubmit={handleSubmit}>
            <FormTitle>Tell us about you</FormTitle>
            <Box
                sx={{
                    display: 'grid',
                    gap: 2,
                    gridTemplateColumns: { sm: '1fr 1fr' },
                }}
            >
                <TextField
                    autoComplete="off"
                    data-cy="first-name"
                    errors={errors?.firstName}
                    label="Legal First Name"
                    name="firstName"
                    onChange={onChange}
                    placeholder="Enter a first name"
                    value={firstName}
                />
                <TextField
                    autoComplete="off"
                    data-cy="last-name"
                    errors={errors?.lastName}
                    label="Last Name"
                    name="lastName"
                    onChange={onChange}
                    placeholder="Enter a last name"
                    value={lastName}
                />
                <TextField
                    autoComplete="off"
                    isOptional
                    label="Preferred First Name"
                    name="preferredName"
                    onChange={onChange}
                    placeholder="Enter a preferred name"
                    value={preferredName}
                />
                <Select
                    autoFocus
                    data-cy="gender"
                    defaultText="Select a gender"
                    defaultValue=""
                    errors={errors?.gender}
                    items={genderList}
                    label="Gender"
                    name="gender"
                    onChange={onChange}
                    optionText="value"
                    optionValue="id"
                    value={gender}
                />
                <DateTextField
                    autoComplete="off"
                    errors={errors?.dateOfBirth}
                    label="Date of Birth"
                    name="dateOfBirth"
                    onChange={onChange}
                    value={dateOfBirth}
                />
                <DateTextField
                    autoComplete="off"
                    data-cy="hire-date"
                    errors={errors?.hireDate}
                    helperText="If you don't know the exact day, put the first of the month."
                    label="Hire Date"
                    name="hireDate"
                    onChange={onChange}
                    value={hireDate}
                />
                <TextField
                    autoComplete="off"
                    data-cy="job-title"
                    errors={errors?.jobTitle}
                    label="Job Title"
                    name="jobTitle"
                    onChange={onChange}
                    placeholder="Enter a job title"
                    value={jobTitle}
                />
                <Select
                    data-cy="usCitizen"
                    defaultText="Select Yes or No"
                    defaultValue=""
                    errors={errors?.usCitizen}
                    items={yesOrNo}
                    label="U.S. Citizen"
                    name="usCitizen"
                    onChange={onChange}
                    optionText="text"
                    optionValue="value"
                    value={usCitizen}
                />
                <Select
                    data-cy="isLegalResident"
                    defaultText="Select Yes or No"
                    defaultValue=""
                    errors={errors?.isLegalResident}
                    items={yesOrNo}
                    label="Legal Resident of Your State"
                    name="isLegalResident"
                    onChange={onChange}
                    optionText="text"
                    optionValue="value"
                    value={isLegalResident}
                />
                <Select
                    data-cy="isIncarcerated"
                    defaultText="Select Yes or No"
                    defaultValue=""
                    errors={errors?.isIncarcerated}
                    helperText="Select yes if you are currently incarcerated with more than 60 days left to serve before release as a result of a conviction, not just pending disposition of charges."
                    items={yesOrNo}
                    label="Incarcerated"
                    name="isIncarcerated"
                    onChange={onChange}
                    optionText="text"
                    optionValue="value"
                    value={isIncarcerated}
                />
            </Box>
        </SurveyFormWrapper>
    );
};

export default hot(module)(MemberForm);
