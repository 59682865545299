import { boolean, object, string } from 'yup';

const ONE = 1;
const ONE_HUNDRED = 100;

const baseSchema = object({
    birthdate: string()
        .required()
        .isValidDate()
        .label('Date of Birth'),
    firstName: string()
        .trim()
        .required()
        .max(ONE_HUNDRED)
        .label('First Name'),
    gender: string()
        .required()
        .max(ONE)
        .min(ONE)
        .label('Gender'),
    isIncarcerated: boolean()
        .typeError('Incarcerated is a required field')
        .required()
        .label('Incarcerated'),
    isLegalResident: boolean()
        .typeError('Legal Resident of Your State is a required field')
        .required()
        .label('Legal Resident of Your State'),
    lastName: string()
        .trim()
        .required()
        .max(ONE_HUNDRED)
        .label('Last Name'),
    preferredName: string()
        .nullable()
        .trim()
        .max(ONE_HUNDRED)
        .label('Preferred Name'),
    usCitizen: boolean()
        .typeError('U.S. Citizen is a required field')
        .required()
        .label('U.S. Citizen'),
    usesTobacco: boolean()
        .typeError('Tobacco Use is a required field')
        .required()
        .label('Tobacco Use'),
});

//schema for personal info form
export const personalInfoSchema = baseSchema.concat(
    object({
        phone: string()
            .required()
            .isValidPhoneNumber()
            .label('Phone Number'),
    })
);

// schema for household member form
export const householdMembersSchema = baseSchema;
