import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { HouseholdMemberTypes } from 'api/generated/enums';
import { Address, IAddress } from 'api/generated/models';
import AddressInput from 'components/addressInput/AddressInput';
import { validateAddressInputs } from 'components/addressInput/addressInputActions';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import DateTextField from 'components/DateTextField';
import Form from 'components/Form';
import Select from 'components/Select';
import TextField from 'components/TextField';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import {
    removeHouseholdMember,
    SAVE_HOUSEHOLD_MEMBER,
    saveHouseholdMember,
    setIsReviewStarted,
} from 'pages/survey/surveyActions';
import { ISurveyHouseholdMember } from 'pages/survey/surveyState';
import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { yesOrNo } from 'reducers/options';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { v4 as guid } from 'uuid';
import { boolean, object, string } from 'yup';

const ONE_HUNDRED = 100;
const schema = object({
    dateOfBirth: string()
        .required()
        .isValidDate()
        .label('Date of Birth'),
    firstName: string()
        .trim()
        .required()
        .max(ONE_HUNDRED)
        .label('First Name'),
    gender: string()
        .required()
        .label('Gender'),
    isIncarcerated: boolean()
        .typeError('Incarcerated is a required field')
        .required()
        .label('Incarcerated'),
    isLegalResident: boolean()
        .typeError('Legal Resident of Your State is a required field')
        .required()
        .label('Legal Resident of Your State'),
    lastName: string()
        .trim()
        .required()
        .max(ONE_HUNDRED)
        .label('Last Name'),
    usCitizen: boolean()
        .typeError('U.S. Citizen is a required field')
        .required()
        .label('U.S. Citizen'),
});

type IHouseholdMemberModalProps = {
    handleClose: () => void;
    householdMember: ISurveyHouseholdMember;
    isEdit?: boolean;
};

const HouseholdMemberModal = ({
    handleClose,
    householdMember,
    isEdit,
}: IHouseholdMemberModalProps) => {
    const dispatch = useThunkDispatch();
    const { address, genderList, prepopulatedHouseholdMemberIds, showActivity, year } = useSelector(
        (state: AppStore) => ({
            address: state.addressInputState.addressInputs,
            genderList: state.options.genderList,
            prepopulatedHouseholdMemberIds:
                state.surveyState.household.prepopulatedHouseholdMemberIds,
            showActivity: hasApiActivity(state, SAVE_HOUSEHOLD_MEMBER),
            year: state.surveyState.incomeYear,
        })
    );
    const isPrepopulated = prepopulatedHouseholdMemberIds?.some(
        (x) => x === householdMember.householdMemberId
    );
    const [shouldRemove, setShouldRemove] = useState(false);
    const [dateOfBirth, setDateOfBirth] = useState(
        formatDateForDisplay(householdMember.dateOfBirth) ?? ''
    );
    const [firstName, setFirstName] = useState(householdMember.firstName ?? '');
    const [gender, setGender] = useState(householdMember.gender ?? '');
    const [lastName, setLastName] = useState(householdMember.lastName ?? '');
    const [preferredName, setPreferredName] = useState(householdMember.preferredName ?? '');
    const [usCitizen, setUsCitizen] = useState(householdMember.usCitizen);
    const [isLegalResident, setIsLegalResident] = useState(householdMember.isLegalResident);
    const [isIncarcerated, setIsIncarcerated] = useState(householdMember.isIncarcerated);
    const [hasDifferentAddressFromPrimary, setHasDifferentAddressFromPrimary] = useState(
        householdMember.hasDifferentAddressFromPrimary
    );
    const { errors, validate } = useForm(schema);

    const householdMemberTypeId = useMemo(() => householdMember.householdMemberTypeId, [
        householdMember.householdMemberTypeId,
    ]);
    const householdMemberId = useMemo(() => householdMember.householdMemberId ?? guid(), [
        householdMember.householdMemberId,
    ]);

    const handleSubmit = useCallback(async () => {
        const isValidAddress =
            !hasDifferentAddressFromPrimary || (await dispatch(validateAddressInputs(address)));
        const { isValid, data: hhMember } = await validate({
            dateOfBirth,
            firstName,
            gender,
            hasDifferentAddressFromPrimary,
            householdMemberId,
            householdMemberTypeId,
            isIncarcerated,
            isLegalResident,
            lastName,
            preferredName,
            usCitizen,
            address: new Address(address as IAddress),
        });
        if (isValid && isValidAddress && hasValue(hhMember)) {
            dispatch(setIsReviewStarted(false));
            if (!hasDifferentAddressFromPrimary) {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete hhMember['address'];
            }

            dispatch(saveHouseholdMember(hhMember));

            handleClose();
        }
    }, [
        hasDifferentAddressFromPrimary,
        dispatch,
        address,
        validate,
        dateOfBirth,
        firstName,
        gender,
        householdMemberId,
        householdMemberTypeId,
        isIncarcerated,
        isLegalResident,
        lastName,
        preferredName,
        usCitizen,
        handleClose,
    ]);
    const handleRemove = useCallback(() => {
        if (shouldRemove) {
            dispatch(setIsReviewStarted(false));
            dispatch(
                removeHouseholdMember({
                    householdMemberId,
                })
            );
            handleClose();
        }
        setShouldRemove(true);
    }, [dispatch, handleClose, householdMemberId, shouldRemove]);

    const handleTitle = () => {
        switch (householdMemberTypeId) {
            case HouseholdMemberTypes.Unclaimed:
                return 'Your Unclaimed Household Member';
            case HouseholdMemberTypes.IchraChild:
                return 'Your Child';
            default:
                return `Your ${HouseholdMemberTypes[householdMemberTypeId]}`;
        }
    };

    return (
        <Modal onHide={handleClose} scrollable show size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{handleTitle()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="household-member-modal" isLoading={showActivity} onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            display: 'grid',
                            gap: 2,
                            gridTemplateColumns: { sm: '1fr 1fr' },
                        }}
                    >
                        <TextField
                            autoFocus
                            data-cy="first-name"
                            disabled={isPrepopulated}
                            errors={errors?.firstName}
                            label="Legal First Name"
                            name="firstName"
                            onChange={onChange(setFirstName)}
                            placeholder="Enter a first name"
                            value={firstName}
                        />
                        <TextField
                            data-cy="last-name"
                            disabled={isPrepopulated}
                            errors={errors?.lastName}
                            label="Last Name"
                            name="lastName"
                            onChange={onChange(setLastName)}
                            placeholder="Enter a last name"
                            value={lastName}
                        />
                        <TextField
                            data-cy="preferred-name"
                            isOptional
                            label="Preferred First Name"
                            name="preferredName"
                            onChange={onChange(setPreferredName)}
                            placeholder="Enter a preferred name"
                            value={preferredName}
                        />
                        <Select
                            data-cy="gender"
                            defaultText="Select a gender"
                            defaultValue=""
                            errors={errors?.gender}
                            items={genderList}
                            label="Gender"
                            name="gender"
                            onChange={onChange(setGender)}
                            optionText="value"
                            optionValue="id"
                            value={gender}
                        />
                        <DateTextField
                            errors={errors?.dateOfBirth}
                            label="Date of Birth"
                            name="dateOfBirth"
                            onChange={onChange(setDateOfBirth)}
                            value={dateOfBirth}
                        />
                        <Select
                            data-cy="usCitizen"
                            defaultText="Select Yes or No"
                            defaultValue=""
                            errors={errors?.usCitizen}
                            items={yesOrNo}
                            label="U.S. Citizen"
                            name="usCitizen"
                            onChange={onChange(setUsCitizen)}
                            optionText="text"
                            optionValue="value"
                            value={usCitizen}
                        />
                        <Select
                            data-cy="isLegalResident"
                            defaultText="Select Yes or No"
                            defaultValue=""
                            errors={errors?.isLegalResident}
                            items={yesOrNo}
                            label="Legal Resident of Your State"
                            name="isLegalResident"
                            onChange={onChange(setIsLegalResident)}
                            optionText="text"
                            optionValue="value"
                            value={isLegalResident}
                        />
                        <Select
                            data-cy="isIncarcerated"
                            defaultText="Select Yes or No"
                            defaultValue=""
                            errors={errors?.isIncarcerated}
                            helperText="Select yes if they are currently incarcerated with more than 60 days left to serve before release as a result of a conviction, not just pending disposition of charges."
                            items={yesOrNo}
                            label="Incarcerated"
                            name="isIncarcerated"
                            onChange={onChange(setIsIncarcerated)}
                            optionText="text"
                            optionValue="value"
                            value={isIncarcerated}
                        />
                    </Box>
                    <Checkbox
                        checked={!hasDifferentAddressFromPrimary}
                        data-cy="same-address-checkbox"
                        label="Has same address?"
                        onChange={({ target: { checked } }) =>
                            setHasDifferentAddressFromPrimary(!checked)
                        }
                    />
                    <Collapse in={hasDifferentAddressFromPrimary}>
                        <AddressInput address={householdMember.address} year={year} />
                    </Collapse>
                </Form>
            </Modal.Body>
            <Modal.Footer className="flex-column">
                <Button
                    ButtonClassName="px-7"
                    className="m-0"
                    data-cy="member-submit"
                    form="household-member-modal"
                    isLoading={showActivity}
                    type="submit"
                >
                    Save
                </Button>
                {isEdit && (
                    <Button
                        ButtonClassName="text-danger"
                        className="mt-1"
                        data-cy="remove-member"
                        onClick={handleRemove}
                        onMouseLeave={() => setShouldRemove(false)}
                        variant="text"
                    >
                        {shouldRemove ? 'Are you sure?' : 'Remove'}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(HouseholdMemberModal);
