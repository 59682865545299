import { getStates } from 'actions/getStates';
import { editSocialSecurityNumbers } from 'actions/user/editSocialSecurityNumbers';
import { EntityType } from 'api/generated/enums';
import { IMemberVerifiedInfo } from 'api/generated/models';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import EditableSocialSecurityAttribute from 'components/EditableSocialSecurityAttribute';
import EditableYesNoSelectAttribute from 'components/EditableYesNoSelectAttribute';
import HeightProfileAttribute from 'components/HeightProfileAttribute';
import WeightProfileAttribute from 'components/WeightProfileAttribute';
import GENDERS from 'constants/genders';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { ICommonProps } from 'pages/profile/ProfilePage';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { genderList } from 'reducers/options';
import { formatGender, formatStateNameFromAbbrev } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { string } from 'yup';

type IBasicVerifiedInfoProps = {
    memberVerifiedInfo: IMemberVerifiedInfo | undefined;
    userId: string | undefined;
    verifiedInfoCommonProps: ICommonProps<IMemberVerifiedInfo>;
};

const BasicVerifiedInfo = ({
    memberVerifiedInfo,
    userId,
    verifiedInfoCommonProps,
}: IBasicVerifiedInfoProps) => {
    const dispatch = useThunkDispatch();
    const userProps = useUserProps();
    const { address } = userProps;
    const { selectedYear, states } = useSelector((appState: AppStore) => ({
        selectedYear: +appState.profileState.selectedYear,
        states: appState.states,
    }));

    const [socialSecurityNumber, setSocialSecurityNumber] = useState(
        memberVerifiedInfo?.socialSecurityNumber
    );
    const [gender, setGender] = useState(memberVerifiedInfo?.gender);
    const [usCitizen, setUsCitizen] = useState(memberVerifiedInfo?.usCitizen?.toString() ?? '');
    const [naturalizedCitizen, setNaturalizedCitizen] = useState(
        memberVerifiedInfo?.naturalizedCitizen?.toString() ?? ''
    );
    const [isLegalResident, setIsLegalResident] = useState(
        memberVerifiedInfo?.isLegalResident?.toString() ?? ''
    );
    const [isIncarcerated, setIsIncarcerated] = useState(
        memberVerifiedInfo?.isIncarcerated?.toString() ?? ''
    );
    const [americanIndian, setAmericanIndian] = useState(
        memberVerifiedInfo?.americanIndian?.toString() ?? ''
    );
    const [alaskanNative, setAlaskanNative] = useState(
        memberVerifiedInfo?.alaskanNative?.toString() ?? ''
    );
    const [fullTimeStudent, setFullTimeStudent] = useState(
        memberVerifiedInfo?.fullTimeStudent?.toString() ?? ''
    );
    const [fosterCareAt18OrOlder, setFosterCareAt18OrOlder] = useState(
        memberVerifiedInfo?.fosterCareAt18OrOlder?.toString() ?? ''
    );
    const [specialNeeds, setSpecialNeeds] = useState(
        memberVerifiedInfo?.specialNeeds?.toString() ?? ''
    );

    const saveSocialSecurityNumber = useCallback(
        async (_name, socialSecurityNumber) => {
            if (userId !== undefined) {
                await dispatch(
                    editSocialSecurityNumbers(
                        userId,
                        selectedYear,
                        {
                            [userId]: {
                                socialSecurityNumber,
                                entityId: userId,
                                entityType: EntityType.User,
                            },
                        },
                        true
                    )
                );
            }
        },
        [dispatch, selectedYear, userId]
    );

    const selectedStateName = useMemo(() => formatStateNameFromAbbrev(states, address?.state), [
        address,
        states,
    ]);

    useEffect(() => {
        dispatch(getStates());
    }, [dispatch]);

    const yesNoAttributes: {
        label: string;
        name: keyof IMemberVerifiedInfo;
        setValue: React.Dispatch<React.SetStateAction<string>>;
        value: string;
    }[] = useMemo(
        () => [
            {
                label: 'U.S. Citizen',
                name: 'usCitizen',
                setValue: setUsCitizen,
                value: usCitizen,
            },
            {
                label: 'Naturalized Citizen',
                name: 'naturalizedCitizen',
                setValue: setNaturalizedCitizen,
                value: naturalizedCitizen,
            },
            {
                label: `Legal Resident of ${selectedStateName}`,
                name: 'isLegalResident',
                setValue: setIsLegalResident,
                value: isLegalResident,
            },
            {
                label: 'Incarcerated',
                name: 'isIncarcerated',
                setValue: setIsIncarcerated,
                value: isIncarcerated,
            },
            {
                label: 'American Indian',
                name: 'americanIndian',
                setValue: setAmericanIndian,
                value: americanIndian,
            },
            {
                label: 'Alaskan Native',
                name: 'alaskanNative',
                setValue: setAlaskanNative,
                value: alaskanNative,
            },
            {
                label: 'Full-Time Student',
                name: 'fullTimeStudent',
                setValue: setFullTimeStudent,
                value: fullTimeStudent,
            },
            {
                label: 'Foster Care at 18 or Older',
                name: 'fosterCareAt18OrOlder',
                setValue: setFosterCareAt18OrOlder,
                value: fosterCareAt18OrOlder,
            },
            {
                label: 'Special Needs',
                name: 'specialNeeds',
                setValue: setSpecialNeeds,
                value: specialNeeds,
            },
        ],
        [
            usCitizen,
            naturalizedCitizen,
            isLegalResident,
            isIncarcerated,
            americanIndian,
            alaskanNative,
            fullTimeStudent,
            fosterCareAt18OrOlder,
            specialNeeds,
            selectedStateName,
        ]
    );

    return (
        <React.Fragment>
            <EditableSocialSecurityAttribute
                {...verifiedInfoCommonProps}
                isOptional
                label="Social Security Number"
                name="socialSecurityNumber"
                onChange={onChange(setSocialSecurityNumber)}
                save={saveSocialSecurityNumber}
                userId={userId}
                value={socialSecurityNumber}
            />

            <EditableSelectAttribute
                {...verifiedInfoCommonProps}
                defaultText="Select a gender"
                formatter={(x) => formatGender(x as GENDERS)}
                items={genderList}
                label="Gender"
                name="gender"
                onChange={onChange(setGender)}
                optionText="value"
                optionValue="id"
                validationSchema={string()
                    .required()
                    .label('Gender')}
                value={gender}
            />

            <HeightProfileAttribute
                {...verifiedInfoCommonProps}
                heightInInches={memberVerifiedInfo?.heightInInches}
            />

            <WeightProfileAttribute
                {...verifiedInfoCommonProps}
                weightInPounds={memberVerifiedInfo?.weightInPounds}
            />

            {yesNoAttributes.map(({ name, label, setValue, value }) => (
                <EditableYesNoSelectAttribute
                    {...verifiedInfoCommonProps}
                    isOptional
                    key={name}
                    label={label}
                    name={name}
                    onChange={onChange(setValue)}
                    value={value}
                />
            ))}
        </React.Fragment>
    );
};

export default hot(module)(BasicVerifiedInfo);
