import { ArrowForward } from '@mui/icons-material';
import { Divider, Grid, Typography } from '@mui/material';
import {
    GET_HOUSEHOLD_MEMBERS_ACTION,
    getHouseholdMembers,
} from 'actions/householdMember/getHouseholdMembers';
import {
    GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER_ACTION,
    getMarketplacePlanRatesForPlans,
} from 'actions/marketplacePlan/getMarketplacePlanRatesForPlans';
import { GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION } from 'actions/pathwayBlueprint/getPathwayBlueprintDataForUser';
import { COMPLETE_TASK_ACTION, completeTask } from 'actions/taskFlows/completeTask';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { listAncillaryBenefits } from 'actions/teamBenefit/listAncillaryBenefits';
import { EnrollmentStatuses, PlanListing, PlanTypeIds } from 'api/generated/enums';
import { IMarketplacePlanDto, ISelectedPlansDto, TaskDto } from 'api/generated/models';
import Button from 'components/Button';
import PathwayTitle from 'components/PathwayTitle';
import ReimbursementMyCostTooltip from 'components/ReimbursementMyCostTooltip';
import Skeleton from 'components/Skeleton';
import HelpContact from 'components/helpComponents/HelpContact';
import { PRIOR_COVERAGE_DISCLAIMER_TEXT } from 'constants/disclaimers';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import {
    MAX_WIDTH,
    ichraFlowSkeletonRowProps,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import { PRESCRIPTIONS_PROVIDERS_DISCLAIMER_TEXT } from 'pages/dashboard/medicalBenefitSelection/OnOrOffExchangePathwayPlanDetails';
import MarketplacePlanCard from 'pages/shop/MarketplacePlanCard';
import PlanCardHeader from 'pages/shop/PlanCardHeader';
import { ADVISEMENT_SCHEDULED_MESSAGE } from 'pages/shop/ShopHeader';
import { IShoppingPlan } from 'pages/shop/shopping';
import React, { useEffect, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity, hasCompletedRequest } from 'selectors/activity';
import { formatCurrency } from 'utilities/format';
import { arrayHasValue, hasValue } from 'utilities/index';

const MEDICARE_AGE = 65;

const RecommendedBenefitsPathPage = ({
    currentTask,
    planId,
}: {
    currentTask: TaskDto;
    planId?: string;
}) => {
    const {
        ancillaryBenefits,
        hasFetchedHousehold,
        householdMembers,
        isCompletingTask,
        isLoadingPathway,
        isLoadingPlan,
        isLoadingVisibleFlows,
        plan,
    } = useSelector((state: AppStore) => ({
        ancillaryBenefits: state.ancillaryBenefits,
        hasFetchedHousehold: hasCompletedRequest(state, GET_HOUSEHOLD_MEMBERS_ACTION),
        householdMembers: state.householdMembers,
        isCompletingTask: hasApiActivity(state, COMPLETE_TASK_ACTION),
        isLoadingPathway: hasApiActivity(state, GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION),
        isLoadingPlan: hasApiActivity(state, GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER_ACTION),
        isLoadingVisibleFlows: hasApiActivity(state, LIST_VISIBLE_FLOWS_ACTION),
        plan: state.shoppingPathwayMarketplacePlan[0],
    }));
    const dispatch = useThunkDispatch();
    const { activeWageUp, userId, user, yearlyUserInfo } = useUserProps();
    const isLoading =
        isLoadingPathway || isLoadingPlan || !hasFetchedHousehold || isLoadingVisibleFlows;
    const hhmsCovered = useMemo(() => {
        const hhms: string[] = [];
        householdMembers.forEach((hhm) => {
            if (hhm.needsCoverage && hhm.dateOfBirth.getAge(user?.activeDate) < MEDICARE_AGE) {
                hhms.push(hhm.householdMemberId);
            }
        });
        return hhms;
    }, [householdMembers, user?.activeDate]);
    const isPrimaryCovered = (user?.dateOfBirth?.getAge(user?.activeDate) ?? 0) < MEDICARE_AGE;
    const individualOnly =
        (isPrimaryCovered && hhmsCovered.length === 0) ||
        (!isPrimaryCovered && hhmsCovered.length === 1);
    const excludedMembers = useMemo(() => {
        const memberIds = [];
        if (!isPrimaryCovered) {
            memberIds.push(userId);
        }
        const excludedHhms = householdMembers
            .filter((x) => !hhmsCovered.contains(x.householdMemberId))
            .map((x) => x.householdMemberId);
        memberIds.push(...excludedHhms);
        return memberIds;
    }, [householdMembers, hhmsCovered, isPrimaryCovered, userId]);

    const currentContribution = user?.activeWageUps?.find(
        (awu) => awu.year === user?.activeDate?.getYear()
    );

    const handleSubmit = async (pathwayPlan?: IMarketplacePlanDto) => {
        if (hasValue(pathwayPlan)) {
            await dispatch(
                completeTask(currentTask.globalId, {
                    isWaived: false,
                    planInfoDtos: [
                        {
                            isPrimaryCovered,
                            plan,
                            householdMembersCovered: hhmsCovered,
                            planId: plan?.id,
                            planTypeId: hasValue((plan as IMarketplacePlanDto).exchange)
                                ? PlanTypeIds.Marketplace
                                : PlanTypeIds.OffExchange,
                        },
                    ],
                } as ISelectedPlansDto)
            );
        } else {
            dispatch(completeTask(currentTask.globalId, undefined));
        }
    };

    const memberIchraCost = plan?.majorMedicalBenefitCost?.ichraMajorMedicalBenefitCost;

    const header = (
        <PlanCardHeader
            carrier={plan?.issuer?.name}
            cost={plan?.premium}
            costWithCredit={plan?.premium}
            dataCyPrefix="pathway"
            hidePlanCost
            hideYourCost
            name={plan?.name}
            plan={plan as IShoppingPlan}
            showTaxSavings={false}
        />
    );

    useEffect(() => {
        if (!hasFetchedHousehold) {
            dispatch(getHouseholdMembers(userId, user?.activeDate?.getYear()));
        }
    }, [dispatch, hasFetchedHousehold, user?.activeDate, userId]);

    useEffect(() => {
        if (hasValue(user) && hasValue(planId) && !hasValue(plan)) {
            dispatch(
                getMarketplacePlanRatesForPlans(
                    userId,
                    [planId],
                    excludedMembers,
                    user?.activeDate?.getYear() as number,
                    activeWageUp?.benchmarkType === PlanListing.OffExchange,
                    false,
                    false,
                    GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER_ACTION
                )
            );
        }
    }, [activeWageUp, dispatch, excludedMembers, plan, planId, user, userId]);

    useEffect(() => {
        dispatch(listAncillaryBenefits(userId, true));
    }, [dispatch, userId]);
    const redirectToDashboard = !arrayHasValue(ancillaryBenefits);

    return (
        <Grid
            alignItems="center"
            container
            direction="column"
            item
            maxWidth={MAX_WIDTH}
            rowGap={2}
            width="100%"
        >
            <Grid item xs={12}>
                <PathwayTitle showMessage />
            </Grid>
            <Grid item xs={12}>
                <Typography textAlign="center" variant="body2">
                    Based on your household information, this pathway would be a great fit for you.
                </Typography>
            </Grid>
            <Grid item width="100%">
                <Skeleton
                    height={320}
                    isEnabled={isLoading}
                    rowProps={ichraFlowSkeletonRowProps}
                    width="100%"
                >
                    <MarketplacePlanCard
                        hideCost
                        hideSelectPlan
                        householdMembersCovered={hhmsCovered}
                        individualOnly={individualOnly}
                        isPrimaryCovered={isPrimaryCovered}
                        pathwayPlanCardHeader={header}
                        plan={{ ...plan, isMarketplace: true } as IShoppingPlan}
                    />
                </Skeleton>
            </Grid>
            <Grid container item paddingBottom={2} paddingX={{ md: 10, xs: 3 }} xs={12}>
                <Typography variant="body2">{PRESCRIPTIONS_PROVIDERS_DISCLAIMER_TEXT}</Typography>
            </Grid>
            <Grid container item paddingBottom={4} paddingX={{ md: 10, xs: 3 }} xs={12}>
                <Typography variant="body2">{PRIOR_COVERAGE_DISCLAIMER_TEXT}</Typography>
            </Grid>
            <Skeleton height={35} isEnabled={isLoading} width={460}>
                <Grid item xs={12}>
                    <Typography textAlign="center" variant="h3">
                        Your Major Medical Cost:{' '}
                        {formatCurrency(memberIchraCost, { preserveDecimal: true })} per month
                        <ReimbursementMyCostTooltip
                            memberCost={memberIchraCost}
                            planCost={plan?.premium}
                            reimbursement={currentContribution?.reimbursement}
                        />
                    </Typography>
                </Grid>
            </Skeleton>
            <Grid item width="100%" xs={12}>
                <Divider />
            </Grid>
            <Grid
                alignItems="center"
                container
                direction={{ xl: 'row-reverse', xs: 'column' }}
                item
                justifyContent={{ sm: 'space-between', xs: 'center' }}
                paddingX={2}
                paddingY={2}
                rowGap={2}
                xs={12}
            >
                <Skeleton count={2} height={45} isEnabled={isLoading} width={120}>
                    <Button
                        color="secondary"
                        disabled={isLoadingPlan}
                        isLoading={isCompletingTask}
                        onClick={async () => handleSubmit()}
                        sx={{ alignItems: 'center' }}
                        variant="outlined"
                        xs={12}
                    >
                        See all plans <ArrowForward sx={{ fontSize: '0.9rem' }} />
                    </Button>
                    <Button
                        disabled={isLoadingPlan}
                        isLoading={isCompletingTask}
                        onClick={async () => handleSubmit(plan)}
                        sx={{ paddingInline: 3 }}
                        variant="contained"
                    >
                        Select Recommended Plan
                    </Button>
                </Skeleton>
                <IchraFlowButtons
                    currentTaskId={currentTask.globalId}
                    displayAsRow
                    hideContinueButton
                    isLoading={isLoading}
                    redirectToDashboard={redirectToDashboard}
                    showWaiveButton
                />
            </Grid>
            <Grid item width="100%" xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                {yearlyUserInfo?.enrollmentStatus?.value ===
                EnrollmentStatuses.AdvisementScheduled ? (
                    <Typography textAlign="center" variant="body2">
                        {ADVISEMENT_SCHEDULED_MESSAGE}
                    </Typography>
                ) : (
                    <HelpContact isIchraFlow />
                )}
            </Grid>
        </Grid>
    );
};

export default hot(module)(RecommendedBenefitsPathPage);
