import { Grid, Link, Stack, Typography } from '@mui/material';
import { completeTask } from 'actions/taskFlows/completeTask';
import { editTaskStatus } from 'actions/taskFlows/editTaskStatus';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { Flows, TaskStatuses } from 'api/generated/enums';
import { IMemberTermsDto, TaskDto } from 'api/generated/models';
import AdditionalDisclosures from 'components/AdditionalDisclosures';
import Form from 'components/Form';
import SignableMemberTerms from 'components/SignableMemberTerms';
import Skeleton from 'components/Skeleton';
import { MEMBER_TERMS_GROUP_KEY } from 'constants/pactSafeGroups';
import DesktopContext from 'contexts/DesktopContext';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import {
    MAX_WIDTH,
    findPreviousSiblingTask,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities/index';
import PactSafe from 'utilities/pactSafe';

const DESKTOP_CANVAS_WIDTH = 600;
const MOBILE_CANVAS_WIDTH = 250;
const DEFAULT_MEMBER_TERMS_INTRO_TEXT =
    "In order for us to process your enrollment and provide ongoing services to you, you'll need to agree to Remodel Health's member terms.";
type IAgreeToMemberTermsPageProps = {
    currentTask: TaskDto;
    flowId: Flows;
    introText?: string;
    isModal?: boolean;
    onClose?: () => void;
};

const AgreeToMemberTermsPage = ({
    currentTask,
    flowId,
    introText = DEFAULT_MEMBER_TERMS_INTRO_TEXT,
    isModal,
    onClose,
}: IAgreeToMemberTermsPageProps) => {
    const { user } = useUserProps();
    const [downloadUrl, setDownloadUrl] = useState('');
    const [signature, setSignature] = useState('');
    const [hasAgreed, setHasAgreed] = useState(false);
    const dispatch = useThunkDispatch();
    const { isLoading, userFlow } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, LIST_VISIBLE_FLOWS_ACTION),
        userFlow: state.userFlows.find((x) => x.flowId === flowId),
    }));
    const isDesktop = useContext(DesktopContext);
    const canvasWidth = isDesktop ? DESKTOP_CANVAS_WIDTH : MOBILE_CANVAS_WIDTH;
    const dispatchCompleteMemberTermsTask = (downloadLinkUrl: string) => {
        setDownloadUrl(downloadLinkUrl);
    };
    const downloadUrlListenerFunction = (downloadLinkUrl: string) => {
        dispatchCompleteMemberTermsTask(downloadLinkUrl);
    };
    const previousTask = useMemo(() => {
        if ((userFlow?.tasks?.length ?? 0) > 1) {
            const previousSiblingTask = findPreviousSiblingTask(
                currentTask.globalId,
                userFlow?.tasks ?? []
            );
            const reversedChildTasks = previousSiblingTask?.childTasks?.slice().reverse();
            return (
                reversedChildTasks?.find((x) => x.taskStatusId === TaskStatuses.Completed) ??
                previousSiblingTask
            );
        }
        return undefined;
    }, [currentTask.globalId, userFlow?.tasks]);

    const handlePrevious = async () => {
        if (previousTask) {
            await dispatch(editTaskStatus(previousTask.globalId, TaskStatuses.Incomplete));
        }
    };
    const hasAlreadyAgreedToTerms = useMemo(
        () => hasValue((currentTask.response as IMemberTermsDto)?.downloadUrl),
        [currentTask.response]
    );

    const handleSubmit = async () => {
        if (hasAlreadyAgreedToTerms) {
            await dispatch(completeTask(currentTask.globalId, currentTask.response));
        } else {
            PactSafe.addDownloadUrlListener(downloadUrlListenerFunction);
            PactSafe.updateNameData(user?.firstName ?? '', user?.lastName ?? '');
            PactSafe.agreeToContract(MEMBER_TERMS_GROUP_KEY);
        }
    };
    const needToAgreeContent = (
        <React.Fragment>
            <Typography>
                {introText}
                <br />
                Please review the following terms carefully, and then add your signature.
            </Typography>
            <Form style={{ width: `${canvasWidth}px` }}>
                <SignableMemberTerms
                    canvasWidth={canvasWidth}
                    groupKey={MEMBER_TERMS_GROUP_KEY}
                    hasAgreed={hasAgreed}
                    isOptIn
                    setHasAgreed={setHasAgreed}
                    setSignature={setSignature}
                />
                <AdditionalDisclosures previousTask={previousTask} />
            </Form>
        </React.Fragment>
    );
    const hasAlreadyAgreed = (
        <React.Fragment>
            <React.Fragment>
                <Typography>
                    If you have any questions about our member terms, please reach out to{' '}
                    <a href="mailto:care@remodelhealth.com">care@remodelhealth.com</a>.{' '}
                </Typography>
                <Link
                    href={(currentTask.response as IMemberTermsDto)?.downloadUrl}
                    underline="hover"
                >
                    Download Member Terms
                </Link>
            </React.Fragment>
            <AdditionalDisclosures previousTask={previousTask} />
        </React.Fragment>
    );
    useEffect(() => {
        if (hasValue(downloadUrl)) {
            dispatch(
                completeTask(currentTask.globalId, {
                    downloadUrl,
                    signature,
                } as IMemberTermsDto)
            );
            if (isModal) {
                onClose?.();
            }
        }
    }, [currentTask.globalId, dispatch, downloadUrl, isModal, onClose, signature]);
    return (
        <Stack alignItems="center" direction="column" gap={3} maxWidth={MAX_WIDTH} width="100%">
            <IchraFlowTitle title="Review and Agree to Member Terms" />
            <Skeleton
                isEnabled={isLoading}
                rowProps={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: 3,
                    justifyContent: 'center',
                }}
                structure={[
                    [
                        {
                            columnProps: { flex: 1 },
                            count: 1,
                            height: 50,
                            sx: { mb: 3 },
                            width: '100%',
                        },
                    ],
                    [
                        {
                            columnProps: { alignItems: 'center' },
                            count: 1,
                            height: 500,
                            sx: { mb: 5 },
                            width: 600,
                        },
                    ],
                ]}
            >
                {hasAlreadyAgreedToTerms ? hasAlreadyAgreed : needToAgreeContent}
            </Skeleton>
            <Grid item>
                <IchraFlowButtons
                    currentTaskId={currentTask.globalId}
                    forwardButtonTextOverride={
                        userFlow?.flowId === Flows.SignUpdatedMemberTerms ? 'Submit' : 'Continue'
                    }
                    handleNext={handleSubmit}
                    handlePrevious={handlePrevious}
                    isDisabled={!hasValue(signature) && !hasAlreadyAgreedToTerms}
                    isLoading={isLoading}
                    showPreviousButton={!isModal}
                />
            </Grid>
        </Stack>
    );
};

export default hot(module)(AgreeToMemberTermsPage);
