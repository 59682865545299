import { Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { getStates } from 'actions/getStates';
import { HouseholdMemberTypes, Tasks } from 'api/generated/enums';
import {
    IAddressDto,
    IHouseholdMemberInfoDto,
    IHouseholdMembersDto,
    TaskDto,
} from 'api/generated/models';
import GENDERS from 'constants/genders';
import useThunkDispatch from 'hooks/useThunkDispatch';
import AddressComponent from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/AddressComponent';
import SummaryRowItem from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/SummaryRowItem';
import SummarySectionHeader from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/SummarySectionHeader';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import {
    formatDateForDisplay,
    formatGender,
    formatStateNameFromAbbrev,
    formatYesOrNo,
} from 'utilities/format';
import { arrayHasValue, hasValue } from 'utilities/index';

const HouseholdMembersSection = ({ tasks }: { tasks: TaskDto[] }) => {
    const dispatch = useThunkDispatch();
    const { ssnTask, states } = useSelector((appState: AppStore) => ({
        ssnTask: appState.socialSecurityNumbers,
        states: appState.states,
    }));
    const task = tasks.find((t) => t.taskId === Tasks.HouseholdMembers);
    const taskId = task?.globalId as string;
    const chunk = task?.response as IHouseholdMembersDto;
    const hhms = chunk.householdMembers as IHouseholdMemberInfoDto[];

    const addressTask = tasks.find((t) => t.taskId === Tasks.PrimaryAddressInfo);
    const address = addressTask?.response as IAddressDto;
    const stateAbbrev = address.state;

    const getHouseholdMemberType = (householdMemberTypeId: HouseholdMemberTypes) =>
        householdMemberTypeId === HouseholdMemberTypes.Spouse ? 'Spouse' : 'Child';
    const getHouseholdMemberSsn = (householdMemberId: string | undefined) =>
        ssnTask.socialSecurityNumbers?.find((ssn) => ssn.entityKeyId === householdMemberId)
            ?.socialSecurityNumber;
    const getSelectedStateName = (hhm: IHouseholdMemberInfoDto) => {
        const houseHoldMemberStateAbbrev = hhm.address ? hhm.address.state : stateAbbrev;
        return formatStateNameFromAbbrev(states, houseHoldMemberStateAbbrev);
    };

    useEffect(() => {
        dispatch(getStates());
    }, [dispatch]);

    if (!arrayHasValue(hhms)) {
        return <React.Fragment />;
    }

    return (
        <Grid container item rowGap={2} xs={12}>
            <Grid item xs={12}>
                <SummarySectionHeader label="Household Members" taskId={taskId} />
                <Divider sx={{ md: { mb: 1 }, xs: { mb: 0 } }} />
            </Grid>
            <Grid columnSpacing={12} container item rowGap={6} xs={12}>
                {hhms.map((hhm) => {
                    const hhmType = getHouseholdMemberType(hhm.householdMemberTypeId);
                    const hhmSsn = getHouseholdMemberSsn(hhm.householdMemberId);
                    const selectedStateName = getSelectedStateName(hhm);

                    return (
                        <Grid
                            container
                            direction="column"
                            item
                            key={hhm.householdMemberId}
                            md={6}
                            rowGap={1}
                            xs={12}
                        >
                            <Typography fontWeight={700} mb={1} variant="body1">
                                {hhmType} - {hhm.firstName}
                            </Typography>
                            <SummaryRowItem content={hhm.firstName} label="Legal First Name" />
                            <SummaryRowItem content={hhm.lastName} label="Last Name" />
                            {hasValue(hhm.preferredName) && (
                                <SummaryRowItem
                                    content={hhm.preferredName}
                                    label="Preferred First Name"
                                />
                            )}
                            <SummaryRowItem
                                content={formatGender(hhm.gender as GENDERS)}
                                label="Gender"
                            />
                            <SummaryRowItem
                                content={formatDateForDisplay(hhm.birthdate)}
                                label="Date of Birth"
                            />
                            <SummaryRowItem
                                content={formatYesOrNo(hhm.usesTobacco)}
                                label="Tobacco Use"
                            />
                            <SummaryRowItem
                                content={formatYesOrNo(hhm.usCitizen)}
                                label="U.S. Citizen"
                            />
                            <SummaryRowItem
                                content={formatYesOrNo(hhm.isLegalResident)}
                                label={`Legal Resident of ${selectedStateName}`}
                            />
                            <SummaryRowItem
                                content={formatYesOrNo(hhm.isIncarcerated)}
                                label="Is Incarcerated"
                            />
                            {hhm.householdMemberTypeId === HouseholdMemberTypes.Spouse && (
                                <SummaryRowItem
                                    content={formatYesOrNo(hhm.sameEmployer)}
                                    label="Same Employer as Primary"
                                />
                            )}
                            <SummaryRowItem content={hhmSsn} label="SSN" useMask />
                            {hhm.address && <AddressComponent address={hhm.address} hideHeader />}
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};

export default hot(module)(HouseholdMembersSection);
