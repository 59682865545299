import {
    HealthInsuranceParts,
    HouseholdMemberTypes,
    OtherBenefits,
    OtherCoverageEligibilities,
    TeamStateIds,
} from 'api/generated/enums';
import {
    IDrug,
    IProvider,
    ISurveyHouseholdMemberDto,
    ITeamProfile,
    IUserProfile,
} from 'api/generated/models';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import {
    IOptionWithText,
    ISurveyReviewState,
    ISurveyState,
    otherBenefits,
    rankCoverage,
} from 'pages/survey/surveyState';
import { AppStore } from 'reducers/appReducer';
import { ISurveyIdeonProvider } from 'reducers/surveyIdeonProviders';
import { createSelector } from 'reselect';
import { ITeamProps } from 'selectors';
import { formatDateForDisplay } from 'utilities/format';
import { hasMemberType } from 'utilities/household';
import { convertProviderToIdeonProvider } from 'utilities/providerSearch';

const currentUserProfileSelector = (state: AppStore): IUserProfile =>
    get(state, 'current.userProfile', {});
const currentTeamProfileSelector = (state: AppStore): ITeamProfile =>
    state.current?.teamProfile ?? {};
const householdMembersSelector = (state: AppStore) => state.householdMembers;

export const getShowQualitativeOpenEndedQuestions = ({ teamStateId }: ITeamProps) =>
    ![TeamStateIds.Customer, TeamStateIds.Renewing].includes(teamStateId);

const getNotesWithPrefixSelector = (prefix: string) =>
    createSelector(currentUserProfileSelector, (userProfile) => {
        const { userNotes = [] } = userProfile;
        const providerNotes = userNotes.filter((x) => x.noteText?.startsWith(prefix + ':'));

        return providerNotes.map((x) => {
            x.noteText = x.noteText?.replace(prefix + ':', '');
            return x;
        });
    });

const groupProvidersOrDrugs = <
    T extends { entityId?: string; entityIds?: string[]; name?: string }
>(
    data: T[],
    primaryKey: keyof T
) =>
    reduce(
        groupBy(data, primaryKey),
        (result: T[], providersOrDrugs, key) => {
            if (key !== 'undefined') {
                const entityToAdd = providersOrDrugs[0];
                if (entityToAdd) {
                    entityToAdd.entityIds = providersOrDrugs.map((x) => x.entityId as string);
                    result.push(entityToAdd);
                }
                return result;
            }
            return result.concat(
                reduce(
                    groupBy(providersOrDrugs, 'name'),
                    (undefinedKeyResults: T[], undefinedKeyData) => {
                        const entityToAdd = undefinedKeyData[0];
                        if (entityToAdd) {
                            entityToAdd.entityIds = undefinedKeyData.map(
                                (x) => x.entityId as string
                            );
                            undefinedKeyResults.push(entityToAdd);
                        }
                        return undefinedKeyResults;
                    },
                    []
                )
            );
        },
        []
    );

export const verifiedInfoSelector = createSelector(
    currentUserProfileSelector,
    currentTeamProfileSelector,
    householdMembersSelector,
    getNotesWithPrefixSelector('preExistingConditions'),
    (state: AppStore) => state.customQuestionAnswers,
    (state: AppStore) => state.householdDrugs,
    (state: AppStore) => state.householdProviders,
    (state: AppStore) => state.qualitativeAnswers,
    (
        userProfile,
        teamProfile,
        householdMembers,
        preExistingConditions,
        customQuestionAnswers,
        selectedDrugs,
        selectedProviders,
        qualitativeAnswers
    ): ISurveyReviewState => {
        const { user, address, memberVerifiedInfo, yearlyUserInfo } = userProfile;
        const { team } = teamProfile;

        if (!user || !memberVerifiedInfo || !address) {
            return ({
                contact: {},
                coverage: {},
                household: { members: [] },
                income: {},
                member: {},
            } as unknown) as ISurveyReviewState;
        }
        selectedDrugs.forEach((v) => delete (v as Partial<IDrug>).drugId);
        selectedProviders.forEach((v) => delete (v as Partial<IProvider>).providerId);
        const householdMembersWithOtherCoverage = householdMembers.map((v) => {
            let hasEmployerOtherCoverage = !!(
                v.otherCoverageEligibilities & OtherCoverageEligibilities.DependentEmployer
            );
            if (v.householdMemberTypeId === HouseholdMemberTypes.Spouse) {
                hasEmployerOtherCoverage = !!(
                    memberVerifiedInfo.otherCoverageEligibilities &
                    OtherCoverageEligibilities.SpouseEmployer
                );
            }
            return {
                ...v,
                hasEmployerOtherCoverage,
                hasDifferentAddressFromPrimary: !isEmpty(v.address),
                hasParentOtherCoverage: !!(
                    v.otherCoverageEligibilities & OtherCoverageEligibilities.Parent
                ),
                socialSecurityNumber: undefined,
            };
        }) as ISurveyHouseholdMemberDto[];

        const contact: ISurveyState['contact'] = {
            addressLine1: address.addressLine1,
            addressLine2: address.addressLine2,
            city: address.city,
            county: address.county,
            countyFips: address.countyFips,
            email: user.email,
            phone: user.phone,
            state: address.state,
            zip: address.zip,
        };
        const preExisting = preExistingConditions?.[0]?.noteText ?? '';
        const coverage: ISurveyState['coverage'] = {
            hasFaithBasedSharingInterest: memberVerifiedInfo.hasInterestInFaithBasedSharingOptions?.toString() as string,
            hasHouseholdPreExistingConditions: memberVerifiedInfo.hasHouseholdPreExistingConditions?.toString(),
            hasParentOtherCoverage: !!(
                memberVerifiedInfo.otherCoverageEligibilities & OtherCoverageEligibilities.Parent
            ),
            householdPreExistingConditionsNote: preExisting,
            selectedDrugs: groupProvidersOrDrugs(selectedDrugs, 'rxcui'),
            selectedProviders: groupProvidersOrDrugs(selectedProviders, 'nationalProviderId').map(
                convertProviderToIdeonProvider
            ) as ISurveyIdeonProvider[],
        };
        const household: ISurveyState['household'] = {
            hasDependents: hasMemberType(householdMembers, HouseholdMemberTypes.Dependent),
            hasSpouse: hasMemberType(householdMembers, HouseholdMemberTypes.Spouse),
            hasUnclaimed: hasMemberType(householdMembers, HouseholdMemberTypes.Unclaimed),
            members: householdMembersWithOtherCoverage,
            prepopulatedHouseholdMemberIds: [],
        };
        const income: ISurveyState['income'] = {
            additionalIncome: memberVerifiedInfo.additionalIncome?.toString(),
            deductions: memberVerifiedInfo.deductions?.toString(),
            employeeIncome: memberVerifiedInfo.employeeIncome?.toString(),
            filingStatus: memberVerifiedInfo.filingStatusId?.toString(),
            householdIncome: `${userProfile.totalDependentIncome +
                userProfile.spouseIncome +
                memberVerifiedInfo.totalEmployeeIncome}`,
        };
        householdMembers.forEach((householdMember) => {
            income[householdMember.householdMemberId] = householdMember.income?.toString();
        });
        const member: ISurveyState['member'] = {
            dateOfBirth: formatDateForDisplay(user.dateOfBirth),
            firstName: user.firstName,
            gender: memberVerifiedInfo.gender,
            hireDate: formatDateForDisplay(user.hireDate),
            isEmployed: true,
            isIncarcerated: memberVerifiedInfo.isIncarcerated,
            isLegalResident: memberVerifiedInfo.isLegalResident,
            isPregnant: memberVerifiedInfo.isPregnant,
            isRequired: false,
            jobTitle: user.jobTitle,
            lastName: user.lastName,
            needsCoverage: yearlyUserInfo?.needsMajorMedicalCoverage,
            otherCoverageSource: yearlyUserInfo?.otherCoverageSource,
            preferredName: user.preferredName,
            teamId: user.teamId,
            usCitizen: memberVerifiedInfo.usCitizen,
            userId: user.userId,
            usesTobacco: memberVerifiedInfo.usesTobacco,
        };
        const incomeYear = memberVerifiedInfo.year;
        const { includeFaithBasedQuestionInSurvey, includeQualitativeQuestionsInSurvey } =
            team ?? {};
        const rankCoverageSurveyState = qualitativeAnswers?.healthInsuranceAnswers?.map((x) => ({
            isSelected: true,
            text: `${x.rank + 1}. ${
                rankCoverage.find((name) => name.value === x.healthInsurancePartId)?.text
            }`,
            value: x.healthInsurancePartId,
        })) as IOptionWithText<HealthInsuranceParts>[];

        const otherBenefitsSurveyState = otherBenefits.map((x) => ({
            isSelected: qualitativeAnswers?.otherBenefitsAnswers?.some(
                (otherBenefit) => otherBenefit.otherBenefitId === x.value
            ),
            text: x.text,
            value: x.value,
        })) as IOptionWithText<OtherBenefits>[];

        const customQuestionsObject: Record<string, string | undefined> = {};
        customQuestionAnswers.forEach((customQuestion) => {
            customQuestionsObject[customQuestion.customQuestionId] = customQuestion.answer;
        });

        const qualitative: ISurveyState['qualitative'] = {
            customQuestionAnswers: customQuestionsObject,
            feedback: {
                anyFeedback: qualitativeAnswers?.anyFeedbackAnswer,
                improveHealthInsurance: qualitativeAnswers?.improveCurrentInsuranceAnswer,
                likeCurrentInsurance: qualitativeAnswers?.likeAboutCurrentInsuranceAnswer,
            },
            otherBenefits: otherBenefitsSurveyState,
            rankCoverage: rankCoverageSurveyState,
        };

        return {
            contact,
            coverage,
            household,
            includeFaithBasedQuestionInSurvey,
            includeQualitativeQuestionsInSurvey,
            income,
            incomeYear,
            member,
            qualitative,
        };
    }
);
