import { CLEAR_SURVEY } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { GET_SURVEY_INFO_FOR_USER_ACTION } from 'actions/survey/getSurveyInfoForUser';
import { GET_SURVEY_USER_PROFILE_ACTION } from 'actions/survey/getSurveyUserProfile';
import { HealthInsuranceParts, HouseholdMemberTypes, OtherBenefits } from 'api/generated/enums';
import {
    IAddress,
    IDrug,
    ISurveyHouseholdMemberDto,
    ISurveyInfo,
    IUserProfile,
} from 'api/generated/models';
import { SurveyTabs } from 'constants/surveyTabs';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import {
    REMOVE_HOUSEHOLD_MEMBER,
    SAVE_CONTACT_FORM,
    SAVE_COVERAGE_FORM,
    SAVE_HOUSEHOLD,
    SAVE_HOUSEHOLD_MEMBER,
    SAVE_HOUSEHOLD_MEMBERS,
    SAVE_INCOME_FORM,
    SAVE_MEMBER_FORM,
    SAVE_QUALITATIVE,
    SAVE_SURVEY_USER_PROFILE,
    SET_IS_REVIEW_STARTED,
    SET_TAB_BAR_INDEX,
} from 'pages/survey/surveyActions';
import { ISurveyIdeonProvider } from 'reducers/surveyIdeonProviders';
import { enumToNameValueArray } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';
import { getDisplayFirstName, hasMemberType } from 'utilities/household';

const sortMembers = (members: ISurveyHouseholdMemberDto[]) =>
    orderBy(
        members,
        [
            'householdMemberTypeId',
            (x) => new Date(x.dateOfBirth as string),
            (x) => getDisplayFirstName(x),
        ],
        ['asc', 'asc', 'asc']
    );

type ISurveyMember = {
    address?: IAddress;
    hasDifferentAddressFromPrimary?: boolean;
    hasEmployerOtherCoverage?: boolean;
    hasParentOtherCoverage?: boolean;
    isIncarcerated?: boolean;
    isLegalResident?: boolean;
    usCitizen?: boolean;
};
export type ISurveyHouseholdMember = ISurveyHouseholdMemberDto & ISurveyMember;

export type IOptionWithText<T> = {
    isSelected?: boolean;
    text?: string;
    value?: T;
};

export const otherBenefits = orderBy(
    enumToNameValueArray(OtherBenefits, {
        formatName: startCase,
        nameKey: 'text',
        nameMap: {
            Offers401k403b: '401(k)/403(b)',
        },
    }),
    (x) => x['text']
) as IOptionWithText<OtherBenefits>[];

export const rankCoverage = orderBy(
    enumToNameValueArray(HealthInsuranceParts, {
        formatName: startCase,
        nameKey: 'text',
        nameMap: {
            NameBrandCarrier: 'A Name-Brand Carrier',
        },
    }),
    (x) => x['text']
) as IOptionWithText<HealthInsuranceParts>[];

export type ISurveyIncomeState = {
    additionalIncome?: string;
    deductions?: string;
    employeeIncome?: string;
    filingStatus: string;
    householdIncome?: string;
    [memberId: string]: string | undefined;
};
export const HAS_DEPENDENTS_PROPERTY_NAME = 'hasDependents';
export const HAS_ICHRA_PROPERTY_NAME = 'hasIchraChildren';
export const HAS_UNCLAIMED_PROPERTY_NAME = 'hasUnclaimed';

export type IMemberCoverageSelection = {
    firstName?: string;
    householdMemberId?: string;
    needsCoverage?: boolean;
    otherCoverageSource?: string;
};

export type ISurveyState = {
    contact: {
        addressLine1: string | undefined;
        addressLine2: string | undefined;
        city: string | undefined;
        county: string | undefined;
        countyFips: string | undefined;
        email: string | undefined;
        phone: string | undefined;
        state: string | undefined;
        zip: string | undefined;
    };
    coverage: {
        hasFaithBasedSharingInterest: string;
        hasHouseholdPreExistingConditions: string;
        hasParentOtherCoverage?: boolean;
        householdPreExistingConditionsNote: string;
        selectedDrugs: IDrug[];
        selectedProviders: ISurveyIdeonProvider[];
    };
    household: {
        [HAS_DEPENDENTS_PROPERTY_NAME]?: boolean;
        [HAS_ICHRA_PROPERTY_NAME]?: boolean;
        [HAS_UNCLAIMED_PROPERTY_NAME]?: boolean;
        hasSpouse?: boolean;
        members: (ISurveyHouseholdMemberDto & { income?: number })[] | undefined;
        prepopulatedHouseholdMemberIds: string[] | undefined;
    };
    includeFaithBasedQuestionInSurvey: boolean | undefined;
    includeQualitativeQuestionsInSurvey: boolean | undefined;
    income: ISurveyIncomeState;
    incomeYear?: number;
    isReviewStarted: boolean;
    member: {
        dateOfBirth: string | undefined;
        firstName: string | undefined;
        gender: string | undefined;
        hireDate: string | undefined;
        isEmployed: true;
        isIncarcerated: boolean | undefined;
        isLegalResident: boolean | undefined;
        isPregnant?: boolean;
        isRequired: false;
        jobTitle: string | undefined;
        lastName: string | undefined;
        needsCoverage: boolean | undefined;
        otherCoverageSource: string | undefined;
        preferredName: string | undefined;
        teamId?: string;
        usCitizen: boolean | undefined;
        userId?: string;
        usesTobacco?: boolean;
    };
    qualitative: {
        customQuestionAnswers: Record<string, string | undefined>;
        feedback: {
            anyFeedback: string | undefined;
            improveHealthInsurance: string | undefined;
            likeCurrentInsurance: string | undefined;
        };
        otherBenefits: IOptionWithText<OtherBenefits>[];
        rankCoverage: IOptionWithText<HealthInsuranceParts>[];
    };
    tabIndex: SurveyTabs;
};
const initialState: ISurveyState = {
    contact: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        county: '',
        countyFips: '',
        email: '',
        phone: '',
        state: '',
        zip: '',
    },
    coverage: {
        hasFaithBasedSharingInterest: '',
        hasHouseholdPreExistingConditions: '',
        hasParentOtherCoverage: undefined,
        householdPreExistingConditionsNote: '',
        selectedDrugs: [],
        selectedProviders: [],
    },
    household: {
        [HAS_DEPENDENTS_PROPERTY_NAME]: undefined,
        [HAS_ICHRA_PROPERTY_NAME]: undefined,
        [HAS_UNCLAIMED_PROPERTY_NAME]: undefined,
        hasSpouse: undefined,
        members: [],
        prepopulatedHouseholdMemberIds: [],
    },
    includeFaithBasedQuestionInSurvey: true,
    includeQualitativeQuestionsInSurvey: true,
    income: {
        additionalIncome: undefined,
        deductions: undefined,
        employeeIncome: undefined,
        filingStatus: '',
        householdIncome: undefined,
    },
    incomeYear: undefined,
    isReviewStarted: false,
    member: {
        dateOfBirth: '',
        firstName: '',
        gender: '',
        hireDate: '',
        isEmployed: true,
        isIncarcerated: undefined,
        isLegalResident: undefined,
        isPregnant: undefined,
        isRequired: false,
        jobTitle: '',
        lastName: '',
        needsCoverage: true,
        otherCoverageSource: '',
        preferredName: '',
        teamId: undefined,
        usCitizen: undefined,
        userId: undefined,
        usesTobacco: undefined,
    },
    qualitative: {
        otherBenefits,
        rankCoverage,
        customQuestionAnswers: {},
        feedback: {
            anyFeedback: '',
            improveHealthInsurance: '',
            likeCurrentInsurance: '',
        },
    },
    tabIndex: 0,
};
export type ISurveyUser = ISurveyMember & ISurveyState['member'];

type ISurveyStatePropsToExclude = 'isReviewStarted' | 'tabIndex';
export type ISurveyReviewState = Omit<ISurveyState, ISurveyStatePropsToExclude>;

type ISurveyStateDataType =
    | ISurveyHouseholdMember[]
    | ISurveyHouseholdMemberDto
    | ISurveyIncomeState
    | ISurveyInfo
    | ISurveyState['contact']
    | ISurveyState['coverage']
    | ISurveyState['household']
    | ISurveyState['member']
    | ISurveyState['qualitative']
    | IUserProfile
    | boolean;

export const surveyState = (
    state = initialState,
    {
        type,
        ...action
    }: IAction<ISurveyStateDataType> & {
        householdMemberId?: string;
    }
): ISurveyState => {
    switch (type) {
        case CLEAR_SURVEY:
            return {
                ...initialState,
                coverage: {
                    ...initialState.coverage,
                    selectedDrugs: [],
                    selectedProviders: [],
                },
            };
        case GET_SURVEY_INFO_FOR_USER_ACTION.success:
        case GET_SURVEY_USER_PROFILE_ACTION.success: {
            const data = action.data as ISurveyInfo;
            const userProfile = data.userProfile;
            return {
                ...state,
                contact: {
                    ...state.contact,
                    email: userProfile?.user?.email,
                },
                household: {
                    [HAS_DEPENDENTS_PROPERTY_NAME]: hasMemberType(
                        data.householdMembers,
                        HouseholdMemberTypes.Dependent
                    ),
                    [HAS_ICHRA_PROPERTY_NAME]: hasMemberType(
                        data.householdMembers,
                        HouseholdMemberTypes.IchraChild
                    ),
                    [HAS_UNCLAIMED_PROPERTY_NAME]: hasMemberType(
                        data.householdMembers,
                        HouseholdMemberTypes.Unclaimed
                    ),
                    hasSpouse: hasMemberType(data.householdMembers, HouseholdMemberTypes.Spouse),
                    members: data.householdMembers?.map<ISurveyHouseholdMemberDto>((hhm) => ({
                        ...hhm,
                        hasDifferentAddressFromPrimary: !!hhm.address,
                    })),
                    prepopulatedHouseholdMemberIds: data.householdMembers?.map(
                        (hhm) => hhm.householdMemberId
                    ),
                },
                includeFaithBasedQuestionInSurvey: data.team?.includeFaithBasedQuestionInSurvey,
                includeQualitativeQuestionsInSurvey: data.team?.includeQualitativeQuestionsInSurvey,
                incomeYear: data.incomeYear,
                member: {
                    ...state.member,
                    dateOfBirth: formatDateForDisplay(userProfile?.user?.dateOfBirth ?? ''),
                    firstName: userProfile?.user?.firstName ?? '',
                    hireDate: formatDateForDisplay(userProfile?.user?.hireDate ?? ''),
                    jobTitle: userProfile?.user?.jobTitle ?? '',
                    lastName: userProfile?.user?.lastName ?? '',
                    preferredName: userProfile?.user?.preferredName ?? '',
                    teamId: userProfile?.user?.teamId,
                    userId: userProfile?.user?.userId,
                },
            };
        }
        case SET_TAB_BAR_INDEX:
            return {
                ...state,
                ...action,
            };
        case SET_IS_REVIEW_STARTED:
            return {
                ...state,
                isReviewStarted: action.data as boolean,
            };
        case SAVE_SURVEY_USER_PROFILE:
            return {
                ...state,
                incomeYear:
                    (action.data as IUserProfile).memberVerifiedInfo?.year ?? state.incomeYear,
            };
        case SAVE_MEMBER_FORM:
            return {
                ...state,
                member: {
                    ...state.member,
                    ...(action.data as ISurveyState['member']),
                },
            };
        case SAVE_CONTACT_FORM:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    ...(action.data as ISurveyState['contact']),
                },
            };
        case REMOVE_HOUSEHOLD_MEMBER: {
            const members = state.household.members?.filter(
                (member) => member.householdMemberId !== action.householdMemberId
            );
            return { ...state, household: { ...state.household, members } };
        }
        case SAVE_HOUSEHOLD:
            return {
                ...state,
                household: { ...state.household, ...(action.data as ISurveyState['household']) },
            };
        case SAVE_HOUSEHOLD_MEMBERS:
            return {
                ...state,
                household: {
                    ...state.household,
                    members: sortMembers([...(action.data as ISurveyHouseholdMemberDto[])]),
                },
            };
        case SAVE_HOUSEHOLD_MEMBER: {
            const data = action.data as ISurveyHouseholdMemberDto;
            const members = [
                ...(state.household.members?.filter(
                    (member) => member.householdMemberId !== data.householdMemberId
                ) ?? []),
                { ...data },
            ];
            return { ...state, household: { ...state.household, members: sortMembers(members) } };
        }
        case SAVE_COVERAGE_FORM:
            return {
                ...state,
                coverage: {
                    ...state.coverage,
                    ...(action.data as ISurveyState['coverage']),
                },
            };
        case SAVE_INCOME_FORM:
            return {
                ...state,
                income: {
                    ...state.income,
                    ...(action.data as ISurveyState['income']),
                },
            };
        case SAVE_QUALITATIVE:
            return {
                ...state,
                qualitative: {
                    ...state.qualitative,
                    ...(action.data as ISurveyState['qualitative']),
                },
            };
        default:
            return state;
    }
};
